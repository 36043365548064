import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Link, useParams } from 'react-router-dom';
import axios from '../axiosCall';
// eslint-disable-next-line
import BackToTop from "react-back-to-top-button";
const SpeakerDetail = () => {

    const { id } = useParams();
    const [noData, setNoData] = useState(false)
    const [isExceptionError, setIsExceptionError] = useState(false)
    const [datas, setDatas] = useState(
        {
            id: '',
            createdTime: '',
            LastName: '',
            Role: '',
            FirstName: '',
            FeatureDate: '',
            Company: '',
            LinkedIn: '',
            Tags: '',
            Country: '',
            Notes: '',
            headshot: '',
            customLink: '',
            customText: '',
            loveQuoteName1: '',
            loveQuoteText1: '',
            loveQuoteName2: '',
            loveQuoteText2: '',
            featurePost: '',
            claimPost: '',
        }
    );

    useEffect(() => {
        //alert(id)
        if(id)
            fetchSpeakerDetailData();
        // eslint-disable-next-line
    }, []);
    const fetchSpeakerDetailData = async () => {
        setNoData(false)
        setIsExceptionError(false)
        try {
            axios.defaults.headers.common.Authorization = `Bearer patOWGyZyXOnHB2qW.c10c0c8c922c14e2deb7fe47cda2709fb8d9823aceff6cebbc9842b1093ff1c3`

            const response = await axios.get("tblnBF0EWDRYNq08H/"+id);
            const records = response.data;
            //alert(JSON.stringify(records))

            if(records) {
                let tags = [];
                if(records['fields']['FunctionTag']) {
                    tags = tags.concat(records['fields']['FunctionTag']);
                }
                if(records['fields']['OtherTag']) {
                    tags = tags.concat(records['fields']['OtherTag']);
                }
                if(records['fields']['IndustryTag']) {
                    tags = tags.concat(records['fields']['IndustryTag']);
                }
                let uniqueTags = [];
                if(tags) {
                    uniqueTags = tags.filter((item, index) => tags.indexOf(item) === index);
                }
                let headshotImage  = '/assets/images/user.png';
                if(records["fields"]["Headshot"]) {
                    headshotImage = records["fields"]["Headshot"][0]["url"];
                } else if(records["fields"]["profile-image"]) {
                    headshotImage = records["fields"]["profile-image"][0]["url"];
                } 
                let RoleField  = '';
                if(records["fields"]["Role-Manual"]) {
                    RoleField = records["fields"]["Role-Manual"];
                } else if(records["fields"]["role"]) {
                    RoleField = records["fields"]["role"];
                }  
                let CompanyField  = '';
                if(records["fields"]["Company-Manual"]) {
                    CompanyField = records["fields"]["Company-Manual"];
                } else if(records["fields"]["company"]) {
                    CompanyField = records["fields"]["company"];
                } 
                setDatas(() => {
                    return {
                        id: records['id'],
                        createdTime: records['createdTime'],
                        LastName: records['fields']['Last Name'],
                        Role: RoleField,
                        FirstName: records['fields']['Name'],
                        FeatureDate: records['fields']['Featured_On'],
                        Company: CompanyField,
                        LinkedIn: records['fields']['LinkedIn'],
                        Tags: uniqueTags,
                        Country: records['fields']['Country'],
                        Notes: records['fields']['Top Text for Directory Page'],
                        headshot : headshotImage,
                        customLink: records['fields']['Custom Link 1'],
                        customText: records['fields']['Custom Link 1 - Text'],
                        loveQuoteName1: records['fields']['Love Quote 1 - Name'],
                        loveQuoteText1: records['fields']['Love Quote 1 - Text'],
                        loveQuoteName2: records['fields']['Love Quote 2 - Name'],
                        loveQuoteText2: records['fields']['Love Quote 2 - Text'],
                        featurePost: records['fields']['WW Featured Post'],
                        claimPost: records['fields']['Page Claimed'],
                    };
                });
    
                //console.log('records :::: '+JSON.stringify(speakerDataFromAPI))
    
            } else {
                setNoData(true)
                setDatas(() => {
                    return {
                        id: '',
                        createdTime: '',
                        LastName: '',
                        Role: '',
                        FirstName: '',
                        FeatureDate: '',
                        Company: '',
                        LinkedIn: '',
                        Tags: '',
                        Country: '',
                        Notes: '',
                        headshot: '',
                        customLink: '',
                        customText: '',
                        loveQuoteName1: '',
                        loveQuoteText1: '',
                        loveQuoteName2: '',
                        loveQuoteText2: '',
                        featurePost: '',
                        claimPost: '',
                    };
                });
            }
        }catch (err) {
            //alert('error in API')
            setDatas(() => {
                return {
                    id: '',
                    createdTime: '',
                    LastName: '',
                    Role: '',
                    FirstName: '',
                    FeatureDate: '',
                    Company: '',
                    LinkedIn: '',
                    Tags: '',
                    Country: '',
                    Notes: '',
                    headshot: '',
                    customLink: '',
                    customText: '',
                    loveQuoteName1: '',
                    loveQuoteText1: '',
                    loveQuoteName2: '',
                    loveQuoteText2: '',
                    featurePost: '',
                    claimPost: '',
                };
            });
            setIsExceptionError(true)
        }
    }
    // console.log(datas);
return (
<>
    <Navbar />
    {/* No records found */}
    {
        (noData) && (isExceptionError === false)  && (<div className='no-records-found'>
        <p className='desc'>No Record Found</p>
        </div>)
    }
    {
        (isExceptionError) && (noData === false) && (<div className='no-records-found'>
        <p className='desc'>There is something wrong. Please try after some time.</p>
        </div>)
    }
    {/* End */}
    {
        (noData === false) && (isExceptionError === false) && (<section className="profile-detail-section">
        <div className="container">
            <div className="profile-detail-container">
                <Link to="/" className="back_btn">
                    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19.5 14H8.5" stroke="#FFF" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M13 9.5L8.5 14L13 18.5" stroke="#FFF" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    <span>Back</span>
                </Link>
                <div className="row">
                    <div className="col-lg-4">
                        <div className="profile-info-box">
                            <div className="card">
                                <div className="card-body">
                                    <div className="profile-details">
                                        <div className="user-img">
                                            <img src={(datas.headshot) ? datas.headshot : 'assets/images/user.png'} alt="" className="profile-img" />
                                        </div>
                                        <div className="text-center">
                                            <p className="username">{datas.FirstName} {datas.LastName}</p>
                                            <div className="heading-line"></div>
                                        </div>
                                        <div className="user-other-details">
                                            <ul className="list">
                                                <li>
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td className="title">Role</td>
                                                                <td className="name">{(datas.Role) ? datas.Role : 'N/A'}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="title">Company</td>
                                                                <td className="name">{(datas.Company) ? datas.Company : 'N/A'}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="title">Country</td>
                                                                <td className="name">{(datas.Country) ? datas.Country : 'N/A'}</td>
                                                            </tr>
                                                            {
                                                                (datas.LinkedIn) && (<tr>
                                                                    <td className="name social-td" colSpan="2"><div className="social-links">
                                                                        
                                                                                <a target="_blank" rel="noreferrer" href={datas.LinkedIn}>
                                                                                    <i className="fa fa-linkedin" aria-hidden="true"></i>
                                                                                </a>
                                                                    </div></td>
                                                                    
                                                                </tr>)
                                                            }
                                                        </tbody>
                                                    </table>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    {
                                        (datas.FeatureDate) && (<div className="featured-tag">
                                        <span>FEATURED</span>
                                        </div>)
                                    }
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8">
                        {
                            (datas.featurePost) && (
                                <div className="footer_buttons">
                                    <a className="featured_btn" href="https://www.linkedin.com/feed/update/urn:li:activity:7059584126412091392" target="_blank" rel="noreferrer">View Featured Post</a>
                                </div>
                            )
                        }
                        <div className="other-info-col">
                            <div className="card">
                                <div className="card-body">
                                    <div className="profile-details-desc mb-3">
                                        <h3 className="desc-title"><i className="fa fa-square" aria-hidden="true"></i> Additional Info</h3>
                                        <div className="desc">
                                            <p>{(datas.Notes) ? datas.Notes : 'N/A'}</p>
                                        </div>
                                    </div>

                                    {
                                        (datas.customLink) && (
                                            <div className="custom-links">
                                                <h3 className="desc-title"><i className="fa fa-square" aria-hidden="true"></i> Links & Resources</h3>
                                                <div className="industry-tag list-tags">
                                                {
                                                    (datas.customLink) && 
                                                    (
                                                        <p><a href={datas.customLink} className="link">{datas.customText}</a></p>
                                                    )
                                                }
                                                </div>
                                            </div>
                                        )
                                    }

                                    {
                                        (datas.Tags) &&(
                                            <div className="tags-name">
                                                <h3 className="desc-title"><i className="fa fa-square" aria-hidden="true"></i> Tags</h3>
                                                <div className="industry-tag list-tags">
                                                {
                                                    (datas.Tags) && 
                                                    (datas.Tags.map((y, j) => {
                                                        return (<span key={j}>{y}</span>) 
                                                    }))
                                                }

                                                    {/* <span><a href="#" className="link">Entrepreneur</a></span>
                                                    <span><a href="#" className="link"Entrepreneur>SUPPORTER</a></span> */}
                                                </div>
                                            </div>
                                        )
                                    }
                                    
                                    <div className="love-CTA">
                                        {
                                            (datas.loveQuoteText1) && (
                                                <div className="industry-tag list-tags">
                                                {
                                                    (datas.loveQuoteName1) &&
                                                    (
                                                        <ul className="feature_listing">
                                                            <li>
                                                                <svg width="62" height="44" viewBox="0 0 62 44" fill="#15C5D6" xmlns="http://www.w3.org/2000/svg">
                                                                    <g opacity="1">
                                                                        <path fillRule="evenodd" clipRule="evenodd" d="M61.5466 16.1588C61.5466 7.23516 55.2249 0 47.2324 0C39.2425 0 33.078 7.2291 33.078 16.1482V16.1588C33.078 31.9984 44.2516 43.9985 58.4385 44C60.4373 44 61.4376 43.0134 60.6851 42.5904C60.232 42.3357 59.7845 42.3152 59.1208 42.2847C58.6824 42.2646 58.1495 42.2401 57.4582 42.1409C53.1316 41.52 47.2324 37.8664 46.7161 32.304C46.7526 32.3047 46.7971 32.3076 46.8423 32.3106C46.8959 32.3141 46.9505 32.3176 46.9939 32.3176C54.9878 32.3176 61.5466 25.0825 61.5466 16.1588ZM29.0235 16.1588C29.0235 7.23516 22.7018 0 14.7092 0C6.71938 0 0.554893 7.2291 0.554893 16.1482V16.1588C0.554893 31.9984 11.7285 43.9985 25.9154 44C27.9142 44 28.9145 43.0134 28.162 42.5904C27.7088 42.3357 27.2614 42.3152 26.5977 42.2847C26.1592 42.2646 25.6263 42.2401 24.9351 42.1409C20.6084 41.52 14.7092 37.8664 14.1929 32.304C14.2295 32.3047 14.274 32.3076 14.3192 32.3106C14.3728 32.3141 14.4273 32.3176 14.4707 32.3176C22.4647 32.3176 29.0235 25.0825 29.0235 16.1588Z" fill="#15C5D6"/>
                                                                    </g>
                                                                </svg>
                                                                <div className="cta-section">
                                                                    <p className="button_show">
                                                                        {datas.loveQuoteText1}
                                                                    </p>
                                                                    <p className="name">
                                                                        {datas.loveQuoteName1}
                                                                    </p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <svg width="62" height="44" viewBox="0 0 62 44" fill="#15C5D6" xmlns="http://www.w3.org/2000/svg">
                                                                    <g opacity="1">
                                                                        <path fillRule="evenodd" clipRule="evenodd" d="M61.5466 16.1588C61.5466 7.23516 55.2249 0 47.2324 0C39.2425 0 33.078 7.2291 33.078 16.1482V16.1588C33.078 31.9984 44.2516 43.9985 58.4385 44C60.4373 44 61.4376 43.0134 60.6851 42.5904C60.232 42.3357 59.7845 42.3152 59.1208 42.2847C58.6824 42.2646 58.1495 42.2401 57.4582 42.1409C53.1316 41.52 47.2324 37.8664 46.7161 32.304C46.7526 32.3047 46.7971 32.3076 46.8423 32.3106C46.8959 32.3141 46.9505 32.3176 46.9939 32.3176C54.9878 32.3176 61.5466 25.0825 61.5466 16.1588ZM29.0235 16.1588C29.0235 7.23516 22.7018 0 14.7092 0C6.71938 0 0.554893 7.2291 0.554893 16.1482V16.1588C0.554893 31.9984 11.7285 43.9985 25.9154 44C27.9142 44 28.9145 43.0134 28.162 42.5904C27.7088 42.3357 27.2614 42.3152 26.5977 42.2847C26.1592 42.2646 25.6263 42.2401 24.9351 42.1409C20.6084 41.52 14.7092 37.8664 14.1929 32.304C14.2295 32.3047 14.274 32.3076 14.3192 32.3106C14.3728 32.3141 14.4273 32.3176 14.4707 32.3176C22.4647 32.3176 29.0235 25.0825 29.0235 16.1588Z" fill="#15C5D6"/>
                                                                    </g>
                                                                </svg>
                                                                <div className="cta-section">
                                                                    <p className="button_show">
                                                                        {datas.loveQuoteText2}
                                                                    </p>
                                                                    <p className="name">
                                                                        {datas.loveQuoteName2}
                                                                    </p>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    )
                                                }
                                                </div>
                                            )
                                        }
                                         <div className="desc-title">
                                            <p> Tell us why this woman is so incredible and your review may be featured here!</p>
                                            <p className="love-btn"><a href='https://info.wednesdaywomen.org/show-some-love' target="_blank" rel="noreferrer" className="link">Show Some Love</a></p>
                                        </div>
                                    </div>
                                    {/* {(datas.claimPost) ? '' : ( */}
                                        <div className="claim-section desc-title">
                                            <p>
                                                Is this you? Update your info and get in touch with Wednesday Women team here.
                                            </p>
                                            <Link to="https://info.wednesdaywomen.org/claim-your-page" rel="noreferrer" target="_blank" className='claim_btn'>Claim This Page</Link>
                                           
                                        </div>
                                    {/* )} */}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <BackToTop
				showOnScrollDown
				showAt={50}
				speed={3000}
				easing="easeInOutQuint"
			>
				<button className="myBtn">
                <i className="fa fa-angle-up" aria-hidden="true"></i>
				</button>
		</BackToTop>
    </section>)
    }
    
    <Footer />
</>
);
};
export default SpeakerDetail;