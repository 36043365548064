import React from 'react';
import { Link, NavLink } from "react-router-dom";

const SpeakerList = (props) => {
    const { speakerDataFromAPI, index } = props;
    let tags = [];
    if(speakerDataFromAPI[index]["fields"]["FunctionTag"]) {
        tags = tags.concat(speakerDataFromAPI[index]["fields"]["FunctionTag"]);
    }
    if(speakerDataFromAPI[index]["fields"]["OtherTag"]) {
        tags = tags.concat(speakerDataFromAPI[index]["fields"]["OtherTag"]);
    }
    if(speakerDataFromAPI[index]["fields"]["IndustryTag"]) {
        tags = tags.concat(speakerDataFromAPI[index]["fields"]["IndustryTag"]);
    }
    let uniqueTags = [];
    if(tags) {
        uniqueTags = tags.filter((item, index) => tags.indexOf(item) === index);
    }

    let headshotImage  = '/assets/images/user.png';
    if(speakerDataFromAPI[index]["fields"]["Headshot"]) {
        headshotImage = speakerDataFromAPI[index]["fields"]["Headshot"][0]["url"]
    } else if(speakerDataFromAPI[index]["fields"]["profile-image"]) {
        headshotImage = speakerDataFromAPI[index]["fields"]["profile-image"][0]["url"]
    }  

    let RoleField  = '';
    if(speakerDataFromAPI[index]["fields"]["Role-Manual"]) {
        RoleField = speakerDataFromAPI[index]["fields"]["Role-Manual"];
    } else if(speakerDataFromAPI[index]["fields"]["role"]) {
        RoleField = speakerDataFromAPI[index]["fields"]["role"];
    }  

    let CompanyField  = '';
    if(speakerDataFromAPI[index]["fields"]["Company-Manual"]) {
        CompanyField = speakerDataFromAPI[index]["fields"]["Company-Manual"];
    } else if(speakerDataFromAPI[index]["fields"]["company"]) {
        CompanyField = speakerDataFromAPI[index]["fields"]["company"];
    } 
     
    return (<div className="col" >
        <div className="card">
            <div className="card-body">
            <NavLink  to={`/speaker-detail/${speakerDataFromAPI[index]['id']}`}  className="card-link">
                    <div className="profile-info">
                        <div className="profile-img">
                        <img 
                        src={headshotImage} 
                        alt="" 
                        />
                        </div>
                        <div className="card-name">
                            <span className="f-name">{speakerDataFromAPI[index]["fields"]["Name"]} </span>
                            <span className="f-name">{speakerDataFromAPI[index]["fields"]["Last Name"]}</span>
                        </div>
                        <div className="role-withname">
                            <p className="d-name">{RoleField}</p>
                            <p className="r-name">{CompanyField} </p>
                        </div>
                        {uniqueTags ? (
                        <ul className='feature_listing'>
                            
                                {Object.entries(uniqueTags).map(([keyId, value]) => (
                                    <li>
                                        <Link to="/" className='button_show'>{value}</Link>
                                    </li>
                                ))}
                        </ul>
                         ) : ''}
                    </div>
                    <div className="profile-desc">
                        <p className="desc">
                            {(speakerDataFromAPI[index]["fields"]["Notes"]) ? speakerDataFromAPI[index]["fields"]["Notes"] : 'N/A'}
                        </p>
                        <ul className="location-detail">
                            <li><i className="fa fa-map-marker" aria-hidden="true"></i> <span className="ms-3">{(speakerDataFromAPI[index]["fields"]["Country"]) ? speakerDataFromAPI[index]["fields"]["Country"] : 'N/A'}</span></li>
                            <li><i className="fa fa-language" aria-hidden="true"></i> <span className="ms-2">{(speakerDataFromAPI[index]["fields"]["Language"]) ? speakerDataFromAPI[index]["fields"]["Language"] : 'N/A'}</span></li>
                        </ul>
                    </div>
                    {
                        (speakerDataFromAPI[index]["fields"]["Featured_On"]) &&
                        (<div className="featured-tag">
                            <span>Featured</span>
                        </div>)
                    }
                </NavLink>
            </div>
            <div className="card-footer">
                {
                    (speakerDataFromAPI[index]["fields"]["LinkedIn"]) && (<div className="social-links">
                        <ul className="link-list">
                            <li>
                                <a className='linkedin_url' target="_blank" rel="noreferrer" href={speakerDataFromAPI[index]["fields"]["LinkedIn"]}>
                                {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"/></svg> */}
                                <i className="fa fa-linkedin" aria-hidden="true"></i>
                                </a>
                            </li>
                        </ul>
                    </div>)
                }
                <div className='footer_buttons'>
                {(speakerDataFromAPI[index]["fields"]["WW Featured Post"]) ? (
                    <Link to={speakerDataFromAPI[index]["fields"]["WW Featured Post"]} target="_blank" className='featured_btn'>View Featured Post</Link>
                ) : ''}
                {(speakerDataFromAPI[index]["fields"]["Page Claimed"]) ? '' : (
                    <Link to="https://info.wednesdaywomen.org/claim-your-page" rel="noreferrer" target="_blank" className='claim_btn'>Claim This Page</Link>
                )}
                </div>
            </div>
        </div>
    </div>)

    
};

export default SpeakerList;
