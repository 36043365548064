import React, { useEffect,useState } from "react";
import { useRef } from 'react';
// import FunctionJSONData from "../data/Function";
// import OthersJSONData from "../data/others";
// import IndustryJSONData from "../data/Industry";
import { Link } from "react-router-dom";
// import {ssImg} from "../assets/images/commsor-ss.png";
import { Sorting } from "../components";

const Filter = (props) => {
  const [search, setSearch] = useState(() => sessionStorage.getItem('searchTerm') || '');
  const [Industry, setIndustry] = useState(() => sessionStorage.getItem('IndustryFilter')?.split(',') || []);
  const [Function, setFunction] = useState(() => sessionStorage.getItem('FunctionFilter')?.split(',') || []);
  const [Others, setOthers] = useState(() => sessionStorage.getItem('OthersFilter')?.split(',') || []);
  const [showClearFilter, setShowClearFilter] = useState(false);
  const [podcast, setPodcast] = useState(() => sessionStorage.getItem('PodcastFilter') || '');
  const [linkedIn, setLinkedIn] = useState(() => sessionStorage.getItem('LinkedInFilter') || '');
  const [isClearFilter, setIsClearFilter] = useState(true);
  // const [value, setValue] = useState('all');

  const refFunction = useRef([]);
  const refOthers = useRef([]);
  const refIndustry = useRef([]);
  const refPodcast = useRef([]);
  const refLinkedIn = useRef([]);
  // const [sortBy, setSortBy] = useState('all');
  // const [offsetValue, setOffsetValue] = useState('')
  // const [speakerDataFromAPI, setSpeakerDataFromAPI] = useState([]);
  
  const [IndustryJSONData, setIndustryJSONData] = useState([]);
  const [FunctionJSONData, setFunctionJSONData] = useState([]);
  const [OthersJSONData, setOthersJSONData] = useState([]);
  let functionTag = [];
  let industruTag = [];
  let otherTag = [];
 
  const [count, setCount] = useState(0);

  useEffect(() => {
    if(props.tags) {
      // eslint-disable-next-line
      props.tags.map((key, index) => {
        if(key.fields.FunctionTag) {
          // eslint-disable-next-line
          functionTag = functionTag.concat(key.fields.FunctionTag);
        }
        if(key.fields.IndustryTag) {
          // eslint-disable-next-line
          industruTag = industruTag.concat(key.fields.IndustryTag);
        }
        if(key.fields.OtherTag) {
          // eslint-disable-next-line
          otherTag = otherTag.concat(key.fields.OtherTag);
        }
      });
      
      let uniquefunctionTag = [];
      if(functionTag) {
          uniquefunctionTag = functionTag.filter((item, index) => functionTag.indexOf(item) === index);
          setFunctionJSONData(uniquefunctionTag);
      }
      let uniqueindustruTag = [];
      if(industruTag) {
          uniqueindustruTag = industruTag.filter((item, index) => industruTag.indexOf(item) === index);
          setIndustryJSONData(uniqueindustruTag);
      }
      let uniqueotherTag = [];
      if(otherTag) {
          uniqueotherTag = otherTag.filter((item, index) => otherTag.indexOf(item) === index);
          setOthersJSONData(uniqueotherTag);
      }
    }
  },[props.tags])
 
  useEffect(() => {
    if(count === 0){
      // const applyFilters = () => {
      //   props.filterCallFromComponent(Function, Others, Industry, podcast, linkedIn, isClearFilter);
      // };

    if (FunctionJSONData.length > 0 || IndustryJSONData.length > 0 || OthersJSONData.length > 0) {
      const storedFunction = sessionStorage.getItem('FunctionFilter');
      console.log(storedFunction);
      if (FunctionJSONData.length > 0 && storedFunction) {
        console.log('in');
        const functionArray = storedFunction.split(',');
        setFunction((prev) => {
          const updatedFunction = prev.length === functionArray.length && prev.every((val, index) => val === functionArray[index])
            ? prev
            : functionArray;
          updatedFunction.forEach((value) => {
            const checkboxIndex = FunctionJSONData.indexOf(value);
            if (refFunction.current[checkboxIndex]) {
              refFunction.current[checkboxIndex].checked = true;
            }
          });
          return updatedFunction;
        });
      } 
      else {
        setFunction([]);
        sessionStorage.removeItem('FunctionFilter');
        setShowClearFilter(false)
      }

      const storedIndustry = sessionStorage.getItem('IndustryFilter');
      if (IndustryJSONData.length > 0 && storedIndustry) {
        const industryArray = storedIndustry.split(',');
        setIndustry((prev) => {
          const updatedIndustry = prev.length === industryArray.length && prev.every((val, index) => val === industryArray[index])
            ? prev
            : industryArray;
            updatedIndustry.forEach((value) => {
            const checkboxIndex = IndustryJSONData.indexOf(value);
            if (refIndustry.current[checkboxIndex]) {
              refIndustry.current[checkboxIndex].checked = true;
            }
          });
          return updatedIndustry;
        });
      }
      else {
        setIndustry([]);
        sessionStorage.removeItem('IndustryFilter');
        setShowClearFilter(false)
      }
      const storedOthers = sessionStorage.getItem('OthersFilter');
      if (OthersJSONData.length > 0 && storedOthers) {
        const OthersArray = storedOthers.split(',');
        setOthers((prev) => {
          const updatedOthers = prev.length === OthersArray.length && prev.every((val, index) => val === OthersArray[index])
            ? prev
            : OthersArray;
            updatedOthers.forEach((value) => {
            const checkboxIndex = OthersJSONData.indexOf(value);
            if (refOthers.current[checkboxIndex]) {
              refOthers.current[checkboxIndex].checked = true;
            }
          });
          return updatedOthers;
        });
      }
      else {
        setOthers([]);
        sessionStorage.removeItem('OthersFilter');
        setShowClearFilter(false)
      }
      // if(Function.length > 0 || Others.length > 0 || Industry.length > 0 || podcast || linkedIn) {
      //   setShowClearFilter(true)
      //   setIsClearFilter(false)
      // }
      console.log(Function);
        
        // updateCheckStatus();
        if(Function.length > 0 || Others.length > 0 || Industry.length > 0 || podcast || linkedIn) {
          // applyFilters();
          updateCheckStatus();
          props.filterCallFromComponent(Function, Others, Industry, podcast, linkedIn, isClearFilter);
         
        }
        console.log('yes yes');
        document.querySelector('.card-list-container .row').style.opacity = 1;
        // if(sessionStorage.getItem('sortValue')) {
        //   props.sortingCall(sessionStorage.getItem('sortValue'))
        // }
        setCount(count + 1)
      } 
    }
     // eslint-disable-next-line
  }, [FunctionJSONData, IndustryJSONData, OthersJSONData]);
  const handleChange = (event) => {

    setSearch(event.target.value);
    sessionStorage.setItem('searchTerm', event.target.value);
    console.log(event.target.value);
    //alert(event.target.value.length)
    if(event.target.value.length >= 2)
    {
      setShowClearFilter(true)
      setIsClearFilter(false)
      props.searchingCall(event.target.value);
    } else {
      props.searchingCall(event.target.value);
      sessionStorage.removeItem('searchTerm');
    }
    // if(sessionStorage.getItem('searchTerm')) {
    //   setShowClearFilter(true)
    //   setIsClearFilter(false)
    //   props.searchingCall(event.target.value);
    // }
  };
  const updateCheckStatus = (event, mode) => {
    
    // const temp = ['']
    // sessionStorage.setItem('OthersFilter', temp.join(','));
    if(event && event.target.checked) {
      console.log(event.target.checked);
      console.log(mode);
      let updatedFunction = [];
      let updatedOthers = [];
      let updatedIndustry = [];
      if(mode === 'Function') {
        updatedFunction = [...Function, event.target.value];
        setFunction(updatedFunction);
        sessionStorage.setItem('FunctionFilter', updatedFunction.join(','));
        
      } else if(mode === 'Industry') {
        updatedIndustry = [...Industry, event.target.value];
        setIndustry(updatedIndustry);
        sessionStorage.setItem('IndustryFilter', updatedIndustry.join(','));
      } else if(mode === 'podcast') {
        setPodcast(event.target.value);
      } else if(mode === 'linkedIn') {
        setLinkedIn(event.target.value);
      } else if(mode === 'Others'){
        updatedOthers = [...Others, event.target.value];
        setOthers(updatedOthers);
        sessionStorage.setItem('OthersFilter', updatedOthers.join(','));
      }
      console.log(updatedFunction);
      props.filterCallFromComponent(updatedFunction, updatedOthers, updatedIndustry, podcast, linkedIn, isClearFilter);
    } else {
      // console.log(event.target.checked);
      console.log(mode);
      let newList = [];
      let newList1 = [];
      let newList2 = [];
      if(mode === 'Function') {
        newList = Function.filter((ind) => ind !== event.target.value);
        setFunction(newList);
        sessionStorage.setItem('FunctionFilter', newList.join(','));

      } else if(mode === 'Industry') {
        newList1 = Industry.filter((loc) => loc !== event.target.value);
        setIndustry(newList1);
        sessionStorage.setItem('IndustryFilter', newList1.join(','));
      } else if(mode === 'Others'){
        newList2 = Others.filter((rol) => rol !== event.target.value);
        setOthers(newList2);
        sessionStorage.setItem('OthersFilter', newList2.join(','));
      }
      props.filterCallFromComponent(newList, newList2, newList1, podcast, linkedIn, isClearFilter);
    } 
  }
  const clearFilter = () => {
    for (let i = 0; i < refFunction.current.length; i++) {

      refFunction.current[i].checked = false;
    }
    for (let i = 0; i < refIndustry.current.length; i++) {

      refIndustry.current[i].checked = false;
    }
    for (let i = 0; i < refOthers.current.length; i++) {

      refOthers.current[i].checked = false;
    }
    for (let i = 0; i < 2; i++) {

      refPodcast.current[i].checked = false;
    }
    for (let i = 0; i < 2; i++) {
     // alert(i)
      refLinkedIn.current[i].checked = false;
    }
    //props.searchingCall('');
    setSearch('');
    sessionStorage.removeItem('searchTerm');
    sessionStorage.removeItem('FunctionFilter');
    sessionStorage.removeItem('IndustryFilter');
    sessionStorage.removeItem('OthersFilter');
    sessionStorage.removeItem('sortValue');

    setPodcast('');
    setLinkedIn('');
    setIndustry([]);
    setFunction([]);
    setOthers([]);
    setShowClearFilter(false)
    setIsClearFilter(true)
  }
  useEffect(() => {
    if(Function.length > 0 || Others.length > 0 || Industry.length > 0 || podcast || linkedIn) {
      setShowClearFilter(true)
      setIsClearFilter(false)
    }
    if(sessionStorage.getItem('searchTerm')) {
      setShowClearFilter(true)
      setIsClearFilter(false)
    }
    // props.filterCallFromComponent(Function,Others,Industry, podcast,linkedIn,isClearFilter)
// eslint-disable-next-line
  },[Function,Others,Industry, podcast,linkedIn]);

return (
    <div className="col-lg-3">
      <div className="filter-container">
        <div className="filter-search">          
            <div className="search-box">
              <input className="form-control" type="text" placeholder="Search" aria-label="Search" value={search}
              onChange={handleChange}
              //onKeyDown={handleKeyDown}
              />
              <Link to="/" className="search-icon">
                <svg width="56" height="40" viewBox="0 0 56 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="56" height="40" fill="#52E0F1"/>
                  <path d="M26.5 26C30.6421 26 34 22.6421 34 18.5C34 14.3579 30.6421 11 26.5 11C22.3579 11 19 14.3579 19 18.5C19 22.6421 22.3579 26 26.5 26Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M37.0004 29L32.6504 24.65" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              </Link>
            </div>
            {
            (showClearFilter) && (<div className="clear-filter">
              {/* eslint-disable-next-line */}
              <a href="/" className="link" onClick={clearFilter}>Clear filters</a>
            </div>)
          }
        </div>

        
        {/* sort by end */}
        <Sorting sortingCall={props.sortingCall} > </Sorting >

        {/* list tabs start */}
        <div className="filter-list-tab_container">
          <div className="filter_by_container">
            <h3 className="filter_by_heading">Filter By</h3>
            <a href="/" className="clear_all" onClick={clearFilter} >Clear All</a>
          </div>
          <div className="filter-list-tab">
            <a className="filter-tab-btn collapsed" data-bs-toggle="collapse" href="#Functionlist" role="button" aria-expanded="false" aria-controls="collapseExample">
              <p className="name">Function 
                <span>
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10 4.16663V15.8333" stroke="#474E5A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M4.1665 10H15.8332" stroke="#474E5A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                </span>
              </p>
            </a>
            <div className="collapse" id="Functionlist">
              <div className="card card-body">
              {FunctionJSONData.map((Functionkey, index) => (
                <div className="form-check" key={index}>
                  <input className="form-check-input" type="checkbox" 
                    onChange={(event) => updateCheckStatus(event, 'Function')}
                    id={Functionkey}
                    value={Functionkey}
                    ref={(element) => { refFunction.current[index] = element }}
                    />
                  <label className="form-check-label" htmlFor={Functionkey}>
                    {Functionkey}
                  </label>
                </div>
                ))}
                
              </div>
            </div>
          </div>
          
          <div className="filter-list-tab">
            <a className="filter-tab-btn collapsed" data-bs-toggle="collapse" href="#Industrylist" role="button" aria-expanded="false" aria-controls="collapseExample">
              <p className="name">Industry
                <span>
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10 4.16663V15.8333" stroke="#474E5A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M4.1665 10H15.8332" stroke="#474E5A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                </span>
              </p>
            </a>
            <div className="collapse" id="Industrylist">
              <div className="card card-body">
              {IndustryJSONData.map((Industrykey, index) => (
                <div className="form-check" key={index}>
                  <input className="form-check-input" type="checkbox" 
                    onChange={(event) => updateCheckStatus(event, 'Industry')}
                    id={Industrykey}
                    value={Industrykey}
                    ref={(element) => { refIndustry.current[index] = element }}
                    />
                  <label className="form-check-label" htmlFor={Industrykey}>
                    {Industrykey}
                  </label>
                </div>
                ))}
              </div>
            </div>
          </div>

          <div className="filter-list-tab">
            <a className="filter-tab-btn collapsed" data-bs-toggle="collapse" href="#Otherslist" role="button" aria-expanded="false" aria-controls="collapseExample">
              <p className="name">Other 
                <span>
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10 4.16663V15.8333" stroke="#474E5A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M4.1665 10H15.8332" stroke="#474E5A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                </span>
              </p>
            </a>
            <div className="collapse" id="Otherslist">
              <div className="card card-body">
              {OthersJSONData.map((Otherskey, index) => (
                <div className="form-check" key={index}>
                  <input className="form-check-input" type="checkbox" 
                    onChange={(event) => updateCheckStatus(event, 'Others')}
                    id={Otherskey}
                    value={Otherskey}
                    ref={(element) => { refOthers.current[index] = element }}
                    />
                  <label className="form-check-label" htmlFor={Otherskey}>
                    {Otherskey}
                  </label>
                </div>
                ))}
                
              </div>
            </div>
          </div>
          <div className="filter-list-tab podcast">
            <a className="filter-tab-btn collapsed" data-bs-toggle="collapse" href="#podcastlist" role="button" aria-expanded="false" aria-controls="collapseExample">
              <p className="name">Podcast Host 
                <span>
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10 4.16663V15.8333" stroke="#474E5A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M4.1665 10H15.8332" stroke="#474E5A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                </span>
              </p>
            </a>
            <div className="collapse" id="podcastlist">
              <div className="card card-body">
                <div className="form-check">
                  <input className="form-check-input" type="radio"  name="podcast"  
                    onChange={(event) => updateCheckStatus(event, 'podcast')}
                    id="podcastYes"
                    value="Yes"
                    ref={(element) => { refPodcast.current[0] = element }}
                  />
                  <label className="form-check-label" htmlFor="podcastYes">
                    Yes
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input" type="radio"  name="podcast" onChange={(event) => updateCheckStatus(event, 'podcast')}
                    id="podcastNo"
                    value="No"
                    ref={(element) => { refPodcast.current[1] = element }} />
                  <label className="form-check-label" htmlFor="podcastNo">
                    No
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="filter-list-tab linkedin">
            <a className="filter-tab-btn collapsed" data-bs-toggle="collapse" href="#speakingtab" role="button" aria-expanded="false" aria-controls="collapseExample">
              <p className="name">LinkedIn
                <span>
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10 4.16663V15.8333" stroke="#474E5A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M4.1665 10H15.8332" stroke="#474E5A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                </span>
              </p>
            </a>
            <div className="collapse" id="speakingtab">
              <div className="card card-body">
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="linkedIn" 
                  onChange={(event) => updateCheckStatus(event, 'linkedIn')}
                  id="linkedInYes"
                  value="Yes"
                  ref={(element) => { refLinkedIn.current[0] = element }}
                  />
                  <label className="form-check-label" htmlFor="linkedInYes">
                    Yes
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="linkedIn" 
                  onChange={(event) => updateCheckStatus(event, 'linkedIn')}
                  id="linkedInNo"
                  value="No"
                  ref={(element) => { refLinkedIn.current[1] = element }} />
                  <label className="form-check-label" htmlFor="linkedInNo">
                    No
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        {/* ss img */}
        <div className="img_ss">
          <img src="assets/images/side-image.png" alt="commsor" />
        </div>
  </div>
);
};

export default Filter;
