import React, { useState, useEffect } from "react";

const Sorting = (props) => {
  // Set initial value from sessionStorage or default to 'all'
  const [value, setValue] = useState(() => sessionStorage.getItem('sortValue') || 'all');
 
  // Handle change in the select dropdown
  const handleChange = (e) => {
    let selectedValue;
    if(e) {
      selectedValue = e.target.value;
    } else {
      selectedValue = sessionStorage.getItem('sortValue')
    }
    setValue(selectedValue);
    sessionStorage.setItem('sortValue', selectedValue); // Save the value in sessionStorage
    props.sortingCall(selectedValue); // Call the sorting function passed from props
    console.log(selectedValue);
  };

  // On component load, call sortingCall with the stored value
  useEffect(() => {
    console.log(value);
     // eslint-disable-next-line
    // props.sortingCall(value);
    if(sessionStorage.getItem('sortValue')) {
      // setTimeout(function() {
        handleChange();
      // },100);
      
    } else {
      props.sortingCall(value);
    }
     // eslint-disable-next-line
  }, []);

  return (
    <div className="sort_by_container">
      <h4 className="sort_by_heading">Sort By</h4>
      <select name="sortOptions" className="form-select" value={value} onChange={handleChange}>
        <option value="all">All</option>
        <option value="featured">Featured</option>
        <option value="not_featured">Nominated</option>
      </select>
    </div>
  );
};

export default Sorting;

// import React, { useState } from "react";

// const Sorting = (props) => {
//   const [value, setValue] = useState('all');
 
//   const handleChange = (e) => {
//     setValue(e.target.value);
//     props.sortingCall(e.target.value)
//   }
//   return (
//     <div className="sort_by_container">
//       <h4 className="sort_by_heading">Sort By </h4>
//       <select name="" className="form-select" value={value} onChange={handleChange}>
//             <option value="all" >All</option>
//             <option value="featured" >Featured</option>
//             <option value="not_featured" >Nominated</option>
//         </select>
//     </div> 
//   );
// };

// export default Sorting;
