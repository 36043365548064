import axios from "axios";

// const axiosInstance = axios.create({baseURL: 'https://api.airtable.com/v0/appxPwvGmlwPPzrS2/'});

const axiosInstance = axios.create({baseURL: 'https://api.airtable.com/v0/appcslpVCYkiztwb6/'});

//Access Token: patz1GYgnvwSBGVnQ.0e18ed74f465707f42df40c293e5eea25264ef81aed03b9e079d50f5c556cfdc

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    return Promise.reject(error);
  }
);


export default axiosInstance;
