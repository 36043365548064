import React from 'react'
import { Link, NavLink } from 'react-router-dom'

const Navbar = () => {
    
    return (
        <nav className="navbar navbar-expand-lg navbar-light sticky-top custom_navbar">
            <div className="container-fluid">
                <div className='navigation'>
                <NavLink className="navbar_link active_link" to="https://www.wednesdaywomen.org/">Home</NavLink>
                <div className="navbar_link hover_link">Community
                    <ul className='sub_nav'>
                        <li>
                            <Link to="https://www.wednesdaywomen.org/chats" className='sub_nav_link'>1:1 Chats</Link>
                        </li>
                        <li>
                            <Link to="https://www.wednesdaywomen.org/events" className='sub_nav_link'>Events</Link>
                        </li>
                        <li>
                            <Link to="https://www.wednesdaywomen.org/partners" className='sub_nav_link'>Partners</Link>
                        </li>
                    </ul>
                </div>
                <div className="navbar_link hover_link">More
                    <ul className='sub_nav'>
                        <li>
                            <Link to="https://www.wednesdaywomen.org/marketplace" className='sub_nav_link'>Marketplace</Link>
                        </li>
                        <li>
                            <Link to="https://www.wednesdaywomen.org/podcast" className='sub_nav_link'>Podcast</Link>
                        </li>
                        <li>
                            <Link to="https://www.wednesdaywomen.org/blog" className='sub_nav_link'>Blog</Link>
                        </li>
                        <li>
                            <Link to="https://www.wednesdaywomen.org/category/all-products" className='sub_nav_link'>Shop</Link>
                        </li>
                    </ul>
                </div>
                <NavLink className="navbar_link hover_link" to="https://directory.wednesdaywomen.org/">Directory</NavLink>
                </div>
            </div>
        </nav>
    )
}

export default Navbar